<script>
import { Tooltip } from 'epmc-patterns/components/v2'
export default {
  components: { Tooltip },
}
</script>
<template>
  <span class="findExactPhraseTooltip tooltip">
    <tooltip>
      <template slot="trigger">
        <span class="findExactPhraseLabel">Find exact phrase</span>
      </template>
      <p>
        <b>Find exact phrase:</b> This search uses double quotes around a
        phrase, to limit the results so they include only those with the exact
        phrase entered.
      </p>
    </tooltip>
  </span>
</template>
